.error_page {
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.page_not_found {
    display: inline-block;
    margin: 0 20px 0 0;
    padding-right: 23px;
    font-size: 24px;
    font-weight: 500;
    vertical-align: top;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
}
.setInline {
    display: inline-block;
}
.subTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
}
.liheight {
    line-height: 48px;
}